var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "print-badge"
  }, [_c('div', {
    staticClass: "print-badge__legal-etity"
  }, [_vm._v(_vm._s(_vm.legal_etity))]), _c('div', {
    staticClass: "print-badge__info-block"
  }, [_c('div', {
    staticClass: "print-badge__name-and-role-block"
  }, [_c('div', {
    staticClass: "print-badge__name-and-role-block"
  }, [_c('div', {
    staticClass: "print-badge__name-user"
  }, [_vm._v(_vm._s(_vm.user.first_name))]), _c('div', {
    staticClass: "print-badge__name-user"
  }, [_vm._v(_vm._s(_vm.user.last_name))])]), _c('div', {
    staticClass: "print-badge__role"
  }, [_vm._v(_vm._s(_vm.role))])])]), _c('div', {
    staticClass: "print-badge__qr-code-and-lable"
  }, [_vm.user.frontol_pass ? _c('div', {
    staticClass: "print-badge__qr-block"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.user.frontol_pass,
      "size": "64"
    }
  })], 1) : _vm._e(), _c('img', {
    attrs: {
      "src": "/img/icons/entersight.svg",
      "alt": "",
      "width": "200",
      "height": "100"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }