<template>
  <b-modal
    id="change-pass-modal"
    centered
    hide-footer
    size="sm"
    @hide="hideModal"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-container__block-title">
          <h5 class="title">Изменение пароля</h5>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <form class="form">
          <label class="form__label">Текущий пароль</label>
          <div class="form__input-block">
            <password-input
              v-model="currentPass"
              input_class="form__input-pass"
              :error="errorCurrentPassword"
            />
          </div>

          <label class="form__label">Новый пароль</label>
          <div class="form__input-block">
            <password-input
              v-model="newPass"
              input_class="form__input-pass"
            />
          </div>

          <label class="form__label">Повторите новый пароль</label>
          <div class="form__input-block">
            <password-input
              v-model="newPassAgain"
              input_class="form__input-pass"
              :error="errorPassAgain"
              :error_text="'Пароль не совпадает'"
            />
          </div>

          <div class="form__buttons-block">
            <div class="b-action-buttons">
              <b-button
                variant="primary"
                class="cursor"
                @click="changePassword"
              >
                Сохранить
              </b-button>
              <b-button
                variant="outline-primary"
                class="cursor ml-3"
                @click="close"
              >
                Отмена
              </b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import PasswordInput from '@/views/shared/components/elements/PasswordInput.vue'
  export default {
    name: 'ChangePassModal',

    components: {
      PasswordInput
    },

    data() {
      return {
        currentPass: '',
        newPass: '',
        newPassAgain: '',
        errorCurrentPassword: false,
        errorPassAgain: false
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('change-pass-modal')
      },

      hideModal() {
        this.currentPass = ''
        this.newPass = ''
        this.newPassAgain = ''
        this.errorCurrentPassword = false
        this.errorPassAgain = false
      },

      async changePassword() {
        if (this.newPass.length < 3 && this.newPassAgain.length < 3) {
          this.$noty.show(`Пароль должен содержать от 3 символов`)
          return
        } else if (this.newPass !== this.newPassAgain) {
          this.errorCurrentPassword = false
          this.errorPassAgain = true
          return
        } else {
          this.errorPassAgain = false

          try {
            const { data } = await this.$apollo.mutate({
              mutation: require('../gql/ChangePassword.gql'),
              variables: {
                old_pass: this.currentPass,
                new_pass: this.newPassAgain
              }
            })
            const status = data?.ChangePassword.status ?? false
            if (status) {
              this.$noty.show(`Успешно!`)
              this.close()
            } else {
              throw Error()
            }
          } catch (err) {
            this.errorCurrentPassword = true
            this.$noty.show(`Что-то пошло не так!`)
            console.log(err)
            return
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 360px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__block-title {
      color: var(--text-primary-gray-700, #313131);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .modal-content {
    width: 100%;

    .form {
      &__label {
        color: var(--text-primary-gray-700, #313131);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 4px;
      }

      &__buttons-block {
        margin-top: 18px;
        margin-bottom: 22px;
      }

      :deep .user-info-card__input-pass {
        width: 100%;
      }
    }
  }
</style>
