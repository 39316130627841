<template>
  <div>
    <!-- <div class="mb-4 nav-div">
      <div style="display: flex; cursor: pointer" @click="goBack">
        <img src="/img/icons/arrow_pagination.svg" alt="" class="mr-2" />
        <span>Назад</span>
      </div>
      <span class="user-name ml-3 mr-3">{{
        (user_header?.last_name ?? ' ') +
        (' ' + (user_header?.first_name ?? ' ')) +
        (' ' + (user_header?.second_name ?? ' '))
      }}</span>
      <span class="mr-2">| </span>
      <span style="color: #00cb91">Статус: Активен</span>
    </div> -->

    <change-pass-modal />

    <set-password-for-cashier-modal @setPassForCashier="setPassForCashier" />

    <fire-an-employee-modal
      :user="user"
      @refetch="refetch"
    />

    <div class="root-div">
      <div>
        <template v-if="activeTab === 0">
          <portal to="badge-print">
            <div class="print-badge">
              <print-badge
                :legal_etity="legalEtity"
                :role="roles[0]?.name ?? ''"
                :user="user"
              />
            </div>
          </portal>
          <div
            :key="activeTab"
            class="mr-3"
          >
            <div class="user-info-card">
              <div class="photo-and-description-container pb-4">
                <div class="photo-and-description-container__info">
                  <div>
                    <img
                      style="border-radius: 8px"
                      src="/img/icons/no-photo-logo.svg"
                      alt=""
                    />
                  </div>
                  <div class="photo-and-description-container__description">
                    <div class="photo-and-description-container__name-user">{{ getUserName }}</div>
                    <div
                      :class="{
                        'photo-and-description-container__statusa-active': !user.input.is_fired,
                        'photo-and-description-container__statusa-no-active': user.input.is_fired
                      }"
                    >
                      Статус: {{ !user.input.is_fired ? 'Активен' : 'Уволен' }}
                    </div>
                  </div>
                </div>
                <div class="user-info-settings-block">
                  <div
                    @click="printBadge"
                    class="user-info-settings-block__badge"
                  >
                    <img
                      src="/img/icons/icon-badge.svg"
                      alt="icon-badge"
                    />
                    <div>Напечатать бейдж</div>
                  </div>

                  <div
                    v-if="!user.input.is_fired"
                    class="user-info-settings-block__change-status"
                    @click="fireAnEmployee"
                  >
                    <img
                      src="/img/icons/fire-employee.svg"
                      alt=""
                    />
                    <div>Уволить</div>
                  </div>

                  <div
                    v-if="user.input.is_fired"
                    class="user-info-settings-block__change-status"
                    @click="reinstateTheEmployee"
                  >
                    <img
                      src="/img/icons/restore-user.svg"
                      alt=""
                    />
                    <div>Восстановить</div>
                  </div>
                </div>
                <!--                <span class="uvolit">Уволить</span>-->
              </div>
              <h5 style="color: #bcbcbc">Личные данные</h5>
              <b-row :col="2">
                <b-col>
                  <label style="color: black">Фамилия</label>
                  <b-form-input v-model="user.last_name" />
                </b-col>
                <b-col>
                  <label style="color: black">Имя</label>
                  <b-form-input v-model="user.first_name" />
                </b-col>
                <div class="w-100 mb-3"></div>
                <b-col>
                  <label style="color: black">Отчество</label>
                  <b-form-input v-model="user.second_name" />
                </b-col>
                <!--                <b-col>-->
                <!--                  <label style="color: black">ИНН</label>-->
                <!--                  <b-form-input />-->
                <!--                </b-col>-->
              </b-row>
              <b-button
                class="user-info-card__input-button"
                @click="updateUser"
                >Сохранить
              </b-button>
            </div>
            <div class="user-info-card">
              <h5
                style="color: #bcbcbc"
                class="mb-2"
              >
                Контакты
              </h5>
              <b-row :col="2">
                <b-col>
                  <label style="color: black">Email</label>
                  <b-form-input v-model="user.email" />
                </b-col>
                <b-col>
                  <label style="color: black">Телефон</label>
                  <b-form-input v-model="user.phone" />
                </b-col>
                <!--        <div class="w-100 mb-3"></div>-->
                <!--        <b-col>-->
                <!--          <label style="color: black">Отчество</label>-->
                <!--          <b-form-input />-->
                <!--        </b-col>-->
                <!--        <b-col>-->
                <!--          <label style="color: black">ИНН</label>-->
                <!--          <b-form-input />-->
                <!--        </b-col>-->
              </b-row>
              <b-button
                class="user-info-card__input-button"
                @click="updateUser"
                >Сохранить
              </b-button>
            </div>

            <div class="user-info-card">
              <h5
                style="color: #bcbcbc"
                class="mb-2"
              >
                Роли и магазины
              </h5>
              <b-row :col="2">
                <b-col>
                  <label>Роль<span class="color-red">*</span></label>
                  <div style="width: 100%">
                    <e-select
                      :value="selectedRoles"
                      class="e-select-el"
                      :options="roles"
                      multiple
                      :max_selected_count="1"
                      :background_color="'#e0edff'"
                      :color_text="'#00A3FF'"
                      :color_close_svg="'#00A3FF'"
                      placeholder="Роли"
                      select_name="roles_select"
                      @input="input_roles"
                    >
                      <template #custom_name="{ item }">
                        <div>
                          <p class="e-select-el__option-name">{{ item.name }}</p>
                        </div>
                      </template>
                    </e-select>
                  </div>
                </b-col>

                <b-col>
                  <label>Магазины<span class="color-red">*</span></label>
                  <div style="width: 100%">
                    <e-select
                      :value="selectedBranches"
                      class="e-select-el"
                      :options="branch_list"
                      multiple
                      :max_selected_count="1"
                      :background_color="'#e0edff'"
                      :color_text="'#00A3FF'"
                      :color_close_svg="'#00A3FF'"
                      placeholder="Магазины"
                      select_name="branch_select"
                      @input="input_branch"
                    >
                      <template #custom_name="{ item }">
                        <div>
                          <p class="e-select-el__option-name">{{ item.name }}</p>
                        </div>
                      </template>
                    </e-select>
                  </div>
                </b-col>
              </b-row>
              <b-button
                class="user-info-card__input-button"
                @click="updateUser"
                >Сохранить</b-button
              >
            </div>
            <!-- <div class="user-info-card">
              <h5
                style="color: #bcbcbc"
                class="mb-2"
              >
                Дополнительно
              </h5>
              <b-row
                :col="2"
                class="mb-3"
              >
                <b-col>
                  <label style="color: black">Комментарий</label>
                  <b-form-textarea style="width: 250px;"/>
                </b-col>
              </b-row>
              <b-button
                class="w-10"
                variant="primary"
                >Сохранить</b-button
              >
            </div> -->
          </div>
        </template>

        <template v-else-if="activeTab === 1">
          <div
            :key="activeTab"
            class="mr-3"
          >
            <div class="user-info-card">
              <template>
                <h5 class="user-info-card__title">Пароль для Энтерсайта</h5>
                <label class="user-info-card__label">Пароль</label>
                <div
                  class="user-info-card__text-blue-info"
                  @click="openChangeModal"
                >
                  Изменить пароль
                </div>
              </template>
            </div>

            <div class="user-info-card">
              <h5 class="user-info-card__title">Пароль для кассы</h5>
              <label class="user-info-card__label">Пароль</label>
              <div class="user-info-card__input-block">
                <b-form-input
                  v-model="passwordForCashRegister"
                  class="user-info-card__input-pass"
                  type="number"
                />
              </div>
              <p class="user-info-card__input-block-text-description">Допустимы только цифры от 3 символов</p>
              <b-button
                class="user-info-card__input-button"
                @click="changePasswordForCashRegister"
              >
                Сохранить</b-button
              >
            </div>
          </div>
        </template>
      </div>
      <div class="navigation-cont">
        <span
          class="nav-item"
          :class="{ 'active-item': activeTab === 0 }"
          @click="setActiveTab(0)"
        >
          <img
            src="/img/icons/users/user_logo.svg"
            class="mr-2"
          />
          <span>Основные данные</span>
        </span>

        <span
          class="nav-item"
          :class="{ 'active-item': activeTab === 1 }"
          @click="setActiveTab(1)"
        >
          <img
            src="/img/icons/users/lock.svg"
            class="mr-2"
          />
          <span>Пароль</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { UserModel } from '@/models/user.model'
  import { mapActions, mapGetters } from 'vuex'
  import ChangePassModal from '../modal/ChangePassModal'
  import SetPasswordForCashierModal from '../modal/SetPasswordForCashierModal'
  import FireAnEmployeeModal from '../modal/FireAnEmployeeModal'
  import PrintBadge from '../components/PrintBadge'

  export default {
    name: 'UserInfo',
    components: {
      ChangePassModal,
      SetPasswordForCashierModal,
      FireAnEmployeeModal,
      PrintBadge
    },
    apollo: {
      Roles: {
        query: require('../gql/Roles.graphql'),
        result({ data }) {
          this.roles = data?.Roles || []
        }
      },
      User: {
        query: require('../gql/User.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.user_id
          }
        },
        result({ data }) {
          this.user = data?.User ? new UserModel(data.User) : new UserModel()
          this.legalEtity = data?.User.branches[0]?.entities[0].name ?? ''

          if (this.user.roles.length > 0) {
            const currentUserRoles = []
            this.user.roles.forEach((el) => {
              if (el.name !== 'root') {
                const role = { id: el.id, name: el.name }
                currentUserRoles.push(role)
              }
            })
            this.selectedRoles = currentUserRoles
          }

          if (this.user.roles?.some((el) => el.name === 'Кассир')) {
            this.iscCshier = true
          }

          if (this.user.branches.length > 0) {
            const currentBranches = []
            this.user.branches.forEach((el) => {
              const branch = { id: el.id, name: el.name }
              currentBranches.push(branch)
            })
            this.selectedBranches = currentBranches
          }

          this.passwordForCashRegister = this.user.frontol_pass ?? ''

          this.user_header = {
            last_name: this.user.last_name,
            first_name: this.user.first_name,
            second_name: this.user.second_name
          }
        },
        skip() {
          return !this.user_id
        }
      }
    },
    data() {
      return {
        user: new UserModel(),
        user_header: {},
        user_id: null,
        activeTab: 0,
        selectedBranches: [],
        selectedRoles: [],
        roles: [],
        iscCshier: false,
        isStatusUser: true,
        passwordForCashRegister: '',
        legalEtity: ''
      }
    },
    computed: {
      ...mapGetters({
        branch_list: 'settings/getBranchList'
      }),
      getUserName() {
        return (
          (this.user_header?.last_name ?? ' ') +
          (' ' + (this.user_header?.first_name ?? ' ')) +
          (' ' + (this.user_header?.second_name ?? ' '))
        )
      }
    },
    watch: {
      $route() {
        this.user_id = this.$route.params.id
      },
      getUserName() {
        this.setBreadcrumbs({
          title: this.getUserName,
          is_go_back: true,
          fixed: true
        })
      }
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    mounted() {
      // this.setBreadcrumbs({
      //   title: 'Профиль сотрудника',
      //   fixed: true,
      //   is_go_back: true
      // })
      this.user_id = this.$route.params.id
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      refetch() {
        this.$apollo.queries.User.refetch()
      },
      fireAnEmployee() {
        this.$bvModal.show('fire-an-employee-modal')
      },
      openChangeModal() {
        this.$bvModal.show('change-pass-modal')
      },
      setActiveTab(tab) {
        this.activeTab = tab
      },
      input_branch(value) {
        this.user.setBranches(value.map((el) => this.branch_list.find((branch) => branch.id === el.id)))
      },
      goBack() {
        this.$router.back()
      },
      input_roles(value) {
        const currentUserRoles = []
        value.forEach((el) => {
          for (let i = 0; i < this.roles.length; i++) {
            if (el.id === this.roles[i].id) {
              currentUserRoles.push(this.roles[i])
            }
          }
        })
        this.user.setRoles(currentUserRoles)
        // this.user.setRoles(value.map((el) => this.roles.find((roles) => roles.id === el.id)))
      },

      async reinstateTheEmployee() {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/UpdateUser.graphql'),
            variables: {
              input: {
                id: this.user.input.id,
                is_fired: false
              }
            }
          })
          this.$noty.show(`Успешно!`)
          this.refetch()
        } catch (err) {
          this.$noty.show(`Что-то пошло не так!`)
          console.log(err)
        }
      },

      async setPassForCashier(data) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/UpdateUser.graphql'),
            variables: {
              input: {
                id: this.user.input.id,
                frontol_pass: data
              }
            }
          })

          this.$noty.show(`Успешно!`)
          this.$apollo.queries.User.refetch()
        } catch (err) {
          this.$noty.show(`Что-то пошло не так!`)
          console.log(err)
        }
      },

      changePasswordForCashRegister() {
        const currentPass = this.user.frontol_pass
        if (this.passwordForCashRegister.length < 3) {
          this.$noty.show(`Пароль дожен быть от 3 символов!`)
          this.passwordForCashRegister = currentPass
          return
        } else {
          this.setPassForCashier(this.passwordForCashRegister)
        }
      },

      async updateUser() {
        if (!this.iscCshier) {
          this.selectedRoles.forEach((el) => {
            if (el.name === 'Кассир' && !this.user.frontol_pass) {
              this.$bvModal.show('set-password-for-cashier-modal')
            }
          })
        }

        try {
          await this.$apollo.mutate({
            mutation: require('../gql/UpdateUser.graphql'),
            variables: {
              input: this.user.updateInput
            }
          })
          this.$noty.show(`Успешно!`)
        } catch (err) {
          this.$noty.show(`Что-то пошло не так!`)
          console.log(err)
        }
      },

      printBadge() {
        let badge = document.getElementById('print-badge')
        badge.style.display = 'block'
        window.print()
      }
    }
  }
</script>

<style scoped lang="scss">
  .user-info-card {
    display: flex;
    flex-direction: column;
    padding: 22px;
    background-color: white;
    width: 50vw;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    margin-bottom: 16px;

    &__title {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 22px;
    }

    &__input-block {
      display: flex;
      align-items: center;
    }

    &__text-blue-info {
      color: var(--text-system-blue-400, #00a3ff);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      text-decoration-line: underline;
      cursor: pointer;
    }

    &__input-block-text-description {
      color: var(--text-secondary-gray-400, #888);
      margin-top: 4px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 0px;
    }

    &__input-button {
      background: var(--text-system-blue-400, #00a3ff);
      color: var(--gray-white, #fff);
      display: block;
      width: 96px;
      margin-top: 18px;
    }

    :deep .user-info-card__input-pass {
      width: 25vw;
    }
  }

  .photo-and-description-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 22px;

    &__info {
      display: flex;
      margin-bottom: 16px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__name-user {
      color: var(--text-primary-gray-700, #313131);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 16px;
      margin-bottom: 6px;
    }

    &__statusa-active {
      color: var(--text-successful-green-400, #00cb91);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 16px;
    }
    &__statusa-no-active {
      color: red;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 16px;
    }
  }

  .user-info-settings-block {
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
    }

    &__badge {
      display: flex;
      margin-right: 16px;
      cursor: pointer;
      color: var(--text-secondary-gray-400, #888);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 32px;
      padding: 8px 12px;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: 2px;
      transition: 0.5s;
    }
    &__badge:hover {
      height: 32px;
      padding: 8px 12px;
      background-color: #f8f8f9;
      display: flex;
      align-items: center;
      border-radius: 2px;
    }

    &__change-status {
      display: flex;
      cursor: pointer;
      color: var(--text-secondary-gray-400, #888);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 32px;
      padding: 8px 12px;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: 2px;
      transition: 0.5s;
    }
    &__change-status:hover {
      height: 32px;
      padding: 8px 12px;
      background-color: #f8f8f9;
      display: flex;
      align-items: center;
      border-radius: 2px;
    }
  }

  .w-10 {
    width: 125px;
    align-items: center;
    justify-content: center;
    padding: 6px 12px !important;
  }

  .root-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .navigation-cont {
    display: flex;
    flex-direction: column;
    background-color: white !important;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    padding: 6px 0;
    width: 220px;
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    color: black;
    transition: 150ms;

    &:hover {
      background-color: #f6f6f6;
      transition: 150ms;
    }
  }

  .active-item {
    border-left: 2px solid #00a3ff;
    background-color: #efefef;
  }

  .nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .user-name {
    font-size: 22px;
    color: black;
    font-weight: 500;
  }
</style>
