<template>
  <div class="print-badge">
    <div class="print-badge__legal-etity">{{ legal_etity }}</div>

    <div class="print-badge__info-block">
      <!-- <div class="print-badge__img-block"></div> -->
      <div class="print-badge__name-and-role-block">
        <div class="print-badge__name-and-role-block">
          <div class="print-badge__name-user">{{ user.first_name }}</div>
          <div class="print-badge__name-user">{{ user.last_name }}</div>
        </div>
        <div class="print-badge__role">{{ role }}</div>
      </div>
    </div>

    <div class="print-badge__qr-code-and-lable">
      <div
        v-if="user.frontol_pass"
        class="print-badge__qr-block"
      >
        <qrcode
          :value="user.frontol_pass"
          size="64"
        />
      </div>
      <img
        src="/img/icons/entersight.svg"
        alt=""
        width="200"
        height="100"
      />
    </div>
  </div>
</template>

<script>
  import { UserModel } from '@/models/user.model'
  import QrcodeVue from 'qrcode.vue'
  export default {
    name: 'PrintBadge',

    components: {
      qrcode: QrcodeVue
    },

    props: {
      legal_etity: {
        type: String,
        default: ''
      },
      user: {
        type: UserModel,
        default: () => {}
      },
      role: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped lang="scss">
  .print-badge {
    width: 12.8cm;
    height: 8cm;
    border: 1px solid black;
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    padding: 16px;

    &__legal-etity {
      color: #000;
      font-family: PT Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 15px;
    }

    &__info-block {
      display: flex;
      margin-bottom: 28px;
      align-items: center;
    }

    &__img-block {
      width: 114px;
      height: 114px;
      border-radius: 10px;
      border: 1px solid black;
      margin-right: 14px;
    }

    &__name-and-role-block {
    }

    &__name-block {
      margin-bottom: 16px;
    }

    &__name-user {
      color: #000;
      font-family: PT Sans;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__role {
      color: #000;
      font-family: PT Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__qr-code-and-lable {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    &__qr-block {
      margin-top: 5px;
      padding-bottom: 0 !important;
    }
  }
</style>
