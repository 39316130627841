<template>
  <b-modal
    id="set-password-for-cashier-modal"
    centered
    hide-footer
    size="sm"
    @hide="hideModal"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-container__block-title">
          <h5 class="title">Установите пользователю пароль для кассы</h5>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <form class="form">
          <div class="form__label-password-block">
            <label class="form__label">Пароль</label>
            <div
              class="cursor form__generate-pass-button"
              @click="getGeneratePass(3)"
            >
              Сгенерировать
            </div>
          </div>
          <div class="form__input-block">
            <b-form-input
              v-model="password"
              class="form__input-pass"
              type="number"
            />
            <div
              v-if="errorInput"
              class="form__error-text"
            >
              Введите пароль от 3 символов!
            </div>
            <p class="form__input-block-text-description">Допустимы только цифры от 3 символов</p>
          </div>

          <div class="form__buttons-block">
            <div class="b-action-buttons">
              <b-button
                variant="primary"
                class="cursor"
                @click="setPassword"
              >
                Сохранить
              </b-button>
              <b-button
                variant="outline-primary"
                class="cursor ml-3"
                @click="close"
              >
                Отмена
              </b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'SetPasswordForCashierModal',

    data() {
      return {
        password: '',
        errorInput: false
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('set-password-for-cashier-modal')
      },

      hideModal() {
        this.password = ''
        this.errorInput = false
      },

      setPassword() {
        if (this.password.length < 3) {
          this.errorInput = true
          return
        } else {
          this.errorInput = false
          this.$emit('setPassForCashier', this.password)
          this.$bvModal.hide('set-password-for-cashier-modal')
        }
      },

      getGeneratePass(countNumber) {
        let result = ''
        for (let i = 0; i < countNumber; i++) {
          let number = Math.floor(Math.random() * (10 - 0) + 0)
          result += number
        }
        this.password = result
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 360px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    color: var(--text-primary-gray-700, #313131);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-content {
    width: 100%;

    .form {
      &__label-password-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__generate-pass-button {
        color: var(--text-system-blue-400, #00a3ff);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;
        margin-bottom: -10px;
      }

      &__label {
        color: var(--text-primary-gray-700, #313131);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 4px;
      }

      &__buttons-block {
        margin-top: 18px;
        margin-bottom: 22px;
      }

      &__input-block-text-description {
        color: var(--text-secondary-gray-400, #888);
        margin-top: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0px;
      }

      &__error-text {
        color: var(--text-alert-red-400, #e53835);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 4px;
      }

      :deep .user-info-card__input-pass {
        width: 100%;
      }
    }
  }
</style>
