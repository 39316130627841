var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "set-password-for-cashier-modal",
      "centered": "",
      "hide-footer": "",
      "size": "sm"
    },
    on: {
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-container__block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Установите пользователю пароль для кассы")])])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('form', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form__label-password-block"
  }, [_c('label', {
    staticClass: "form__label"
  }, [_vm._v("Пароль")]), _c('div', {
    staticClass: "cursor form__generate-pass-button",
    on: {
      "click": function ($event) {
        return _vm.getGeneratePass(3);
      }
    }
  }, [_vm._v(" Сгенерировать ")])]), _c('div', {
    staticClass: "form__input-block"
  }, [_c('b-form-input', {
    staticClass: "form__input-pass",
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm.errorInput ? _c('div', {
    staticClass: "form__error-text"
  }, [_vm._v(" Введите пароль от 3 символов! ")]) : _vm._e(), _c('p', {
    staticClass: "form__input-block-text-description"
  }, [_vm._v("Допустимы только цифры от 3 символов")])], 1), _c('div', {
    staticClass: "form__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.setPassword
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Отмена ")])], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }