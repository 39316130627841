var render = function render(){
  var _vm$roles$0$name, _vm$roles$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('change-pass-modal'), _c('set-password-for-cashier-modal', {
    on: {
      "setPassForCashier": _vm.setPassForCashier
    }
  }), _c('fire-an-employee-modal', {
    attrs: {
      "user": _vm.user
    },
    on: {
      "refetch": _vm.refetch
    }
  }), _c('div', {
    staticClass: "root-div"
  }, [_c('div', [_vm.activeTab === 0 ? [_c('portal', {
    attrs: {
      "to": "badge-print"
    }
  }, [_c('div', {
    staticClass: "print-badge"
  }, [_c('print-badge', {
    attrs: {
      "legal_etity": _vm.legalEtity,
      "role": (_vm$roles$0$name = (_vm$roles$ = _vm.roles[0]) === null || _vm$roles$ === void 0 ? void 0 : _vm$roles$.name) !== null && _vm$roles$0$name !== void 0 ? _vm$roles$0$name : '',
      "user": _vm.user
    }
  })], 1)]), _c('div', {
    key: _vm.activeTab,
    staticClass: "mr-3"
  }, [_c('div', {
    staticClass: "user-info-card"
  }, [_c('div', {
    staticClass: "photo-and-description-container pb-4"
  }, [_c('div', {
    staticClass: "photo-and-description-container__info"
  }, [_vm._m(0), _c('div', {
    staticClass: "photo-and-description-container__description"
  }, [_c('div', {
    staticClass: "photo-and-description-container__name-user"
  }, [_vm._v(_vm._s(_vm.getUserName))]), _c('div', {
    class: {
      'photo-and-description-container__statusa-active': !_vm.user.input.is_fired,
      'photo-and-description-container__statusa-no-active': _vm.user.input.is_fired
    }
  }, [_vm._v(" Статус: " + _vm._s(!_vm.user.input.is_fired ? 'Активен' : 'Уволен') + " ")])])]), _c('div', {
    staticClass: "user-info-settings-block"
  }, [_c('div', {
    staticClass: "user-info-settings-block__badge",
    on: {
      "click": _vm.printBadge
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/icon-badge.svg",
      "alt": "icon-badge"
    }
  }), _c('div', [_vm._v("Напечатать бейдж")])]), !_vm.user.input.is_fired ? _c('div', {
    staticClass: "user-info-settings-block__change-status",
    on: {
      "click": _vm.fireAnEmployee
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/fire-employee.svg",
      "alt": ""
    }
  }), _c('div', [_vm._v("Уволить")])]) : _vm._e(), _vm.user.input.is_fired ? _c('div', {
    staticClass: "user-info-settings-block__change-status",
    on: {
      "click": _vm.reinstateTheEmployee
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/restore-user.svg",
      "alt": ""
    }
  }), _c('div', [_vm._v("Восстановить")])]) : _vm._e()])]), _c('h5', {
    staticStyle: {
      "color": "#bcbcbc"
    }
  }, [_vm._v("Личные данные")]), _c('b-row', {
    attrs: {
      "col": 2
    }
  }, [_c('b-col', [_c('label', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Фамилия")]), _c('b-form-input', {
    model: {
      value: _vm.user.last_name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "last_name", $$v);
      },
      expression: "user.last_name"
    }
  })], 1), _c('b-col', [_c('label', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Имя")]), _c('b-form-input', {
    model: {
      value: _vm.user.first_name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "first_name", $$v);
      },
      expression: "user.first_name"
    }
  })], 1), _c('div', {
    staticClass: "w-100 mb-3"
  }), _c('b-col', [_c('label', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Отчество")]), _c('b-form-input', {
    model: {
      value: _vm.user.second_name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "second_name", $$v);
      },
      expression: "user.second_name"
    }
  })], 1)], 1), _c('b-button', {
    staticClass: "user-info-card__input-button",
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v("Сохранить ")])], 1), _c('div', {
    staticClass: "user-info-card"
  }, [_c('h5', {
    staticClass: "mb-2",
    staticStyle: {
      "color": "#bcbcbc"
    }
  }, [_vm._v(" Контакты ")]), _c('b-row', {
    attrs: {
      "col": 2
    }
  }, [_c('b-col', [_c('label', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Email")]), _c('b-form-input', {
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('b-col', [_c('label', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v("Телефон")]), _c('b-form-input', {
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1)], 1), _c('b-button', {
    staticClass: "user-info-card__input-button",
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v("Сохранить ")])], 1), _c('div', {
    staticClass: "user-info-card"
  }, [_c('h5', {
    staticClass: "mb-2",
    staticStyle: {
      "color": "#bcbcbc"
    }
  }, [_vm._v(" Роли и магазины ")]), _c('b-row', {
    attrs: {
      "col": 2
    }
  }, [_c('b-col', [_c('label', [_vm._v("Роль"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.selectedRoles,
      "options": _vm.roles,
      "multiple": "",
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Роли",
      "select_name": "roles_select"
    },
    on: {
      "input": _vm.input_roles
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }], null, false, 3445187160)
  })], 1)]), _c('b-col', [_c('label', [_vm._v("Магазины"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "value": _vm.selectedBranches,
      "options": _vm.branch_list,
      "multiple": "",
      "max_selected_count": 1,
      "background_color": '#e0edff',
      "color_text": '#00A3FF',
      "color_close_svg": '#00A3FF',
      "placeholder": "Магазины",
      "select_name": "branch_select"
    },
    on: {
      "input": _vm.input_branch
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }], null, false, 3445187160)
  })], 1)])], 1), _c('b-button', {
    staticClass: "user-info-card__input-button",
    on: {
      "click": _vm.updateUser
    }
  }, [_vm._v("Сохранить")])], 1)])] : _vm.activeTab === 1 ? [_c('div', {
    key: _vm.activeTab,
    staticClass: "mr-3"
  }, [_c('div', {
    staticClass: "user-info-card"
  }, [[_c('h5', {
    staticClass: "user-info-card__title"
  }, [_vm._v("Пароль для Энтерсайта")]), _c('label', {
    staticClass: "user-info-card__label"
  }, [_vm._v("Пароль")]), _c('div', {
    staticClass: "user-info-card__text-blue-info",
    on: {
      "click": _vm.openChangeModal
    }
  }, [_vm._v(" Изменить пароль ")])]], 2), _c('div', {
    staticClass: "user-info-card"
  }, [_c('h5', {
    staticClass: "user-info-card__title"
  }, [_vm._v("Пароль для кассы")]), _c('label', {
    staticClass: "user-info-card__label"
  }, [_vm._v("Пароль")]), _c('div', {
    staticClass: "user-info-card__input-block"
  }, [_c('b-form-input', {
    staticClass: "user-info-card__input-pass",
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.passwordForCashRegister,
      callback: function ($$v) {
        _vm.passwordForCashRegister = $$v;
      },
      expression: "passwordForCashRegister"
    }
  })], 1), _c('p', {
    staticClass: "user-info-card__input-block-text-description"
  }, [_vm._v("Допустимы только цифры от 3 символов")]), _c('b-button', {
    staticClass: "user-info-card__input-button",
    on: {
      "click": _vm.changePasswordForCashRegister
    }
  }, [_vm._v(" Сохранить")])], 1)])] : _vm._e()], 2), _c('div', {
    staticClass: "navigation-cont"
  }, [_c('span', {
    staticClass: "nav-item",
    class: {
      'active-item': _vm.activeTab === 0
    },
    on: {
      "click": function ($event) {
        return _vm.setActiveTab(0);
      }
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/users/user_logo.svg"
    }
  }), _c('span', [_vm._v("Основные данные")])]), _c('span', {
    staticClass: "nav-item",
    class: {
      'active-item': _vm.activeTab === 1
    },
    on: {
      "click": function ($event) {
        return _vm.setActiveTab(1);
      }
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/users/lock.svg"
    }
  }), _c('span', [_vm._v("Пароль")])])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticStyle: {
      "border-radius": "8px"
    },
    attrs: {
      "src": "/img/icons/no-photo-logo.svg",
      "alt": ""
    }
  })]);

}]

export { render, staticRenderFns }