var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "change-pass-modal",
      "centered": "",
      "hide-footer": "",
      "size": "sm"
    },
    on: {
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-container__block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Изменение пароля")])])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('form', {
    staticClass: "form"
  }, [_c('label', {
    staticClass: "form__label"
  }, [_vm._v("Текущий пароль")]), _c('div', {
    staticClass: "form__input-block"
  }, [_c('password-input', {
    attrs: {
      "input_class": "form__input-pass",
      "error": _vm.errorCurrentPassword
    },
    model: {
      value: _vm.currentPass,
      callback: function ($$v) {
        _vm.currentPass = $$v;
      },
      expression: "currentPass"
    }
  })], 1), _c('label', {
    staticClass: "form__label"
  }, [_vm._v("Новый пароль")]), _c('div', {
    staticClass: "form__input-block"
  }, [_c('password-input', {
    attrs: {
      "input_class": "form__input-pass"
    },
    model: {
      value: _vm.newPass,
      callback: function ($$v) {
        _vm.newPass = $$v;
      },
      expression: "newPass"
    }
  })], 1), _c('label', {
    staticClass: "form__label"
  }, [_vm._v("Повторите новый пароль")]), _c('div', {
    staticClass: "form__input-block"
  }, [_c('password-input', {
    attrs: {
      "input_class": "form__input-pass",
      "error": _vm.errorPassAgain,
      "error_text": 'Пароль не совпадает'
    },
    model: {
      value: _vm.newPassAgain,
      callback: function ($$v) {
        _vm.newPassAgain = $$v;
      },
      expression: "newPassAgain"
    }
  })], 1), _c('div', {
    staticClass: "form__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.changePassword
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Отмена ")])], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }